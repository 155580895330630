import Amplify from '@aws-amplify/core'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import config from './src/aws-exports'
import store from './src/state/store'
import LogRocket from 'logrocket';


if(process.env.NODE_ENV === "production"){
  LogRocket.init('h4wvyp/blikecom');
}


Amplify.configure(config)

let persistor = persistStore(store)

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}
