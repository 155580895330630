import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import store from '../store'
import { RootState, Thunk, Dispatch } from '../store'
import API from '@aws-amplify/api'
import { discountAPI } from '../../config'
import { navigate } from 'gatsby'
import { clearAccessories } from '../accessories'

import { fetchQuote, resetQuote } from '../quote'

const initialState = {
    code: null,
    associatedProduct: null,
    amount: 0
}

// Slice
const discountSlice = createSlice({
    name: 'discount',
    initialState,
    reducers: {
        addDiscount: (state, action) => {
            const { payload } = action
            return {
                ...state,
                ...payload
            }
        },
        removeDiscount: (state, action) => {
            const { payload } = action
            return {
                ...state,
                discount: initialState
            }
        }
    },
    extraReducers: {
        'selection/removeAccessory': (state, action) => {
            console.log("action.payload.accessory", action.payload.accessory, current(state).associatedProduct);
            if (action.payload.accessory === current(state).associatedProduct) {
                return initialState
            }
        },
    },
})

// Reducers
export default discountSlice.reducer

// Selectors
export const discountSelector = state => state.discount

// Actions
export const {
    addDiscount,
    removeDiscount,
} = discountSlice.actions

// Thunks
export const applyDiscount = (
    code
) => async (dispatch, state) => {

    let { discount } = await API.get(discountAPI.name, `${discountAPI.path}/${code}`);
    if (discount && discount?.['Code']) {

        if (discount['Discount Type'] === "accessory") {
            if (state().selection.selectedAccessories.includes(discount['Associated Product'])) {
                dispatch(addDiscount({
                    amount: discount['Amount'],
                    associatedProduct: discount['Associated Product'],
                    code: discount['Code'],
                    description: discount['Description']
                }
                ))
                alert("discount applied")
            } else {
                alert("No eligiable product selected")
            }
        }

    } else {
        alert("code not valid");
    }

}
