import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import store from '../store'
import { RootState, Thunk, Dispatch } from '../store'
import API from '@aws-amplify/api'
import { bikeAPI, recycleAPI } from '../../config'
import { clearAccessories } from '../accessories'
import { navigate } from 'gatsby'

export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark',
}

const initialState = {
  models: {
    byId: {},
    allIds: [],
  },
  accessories: null,
}

// Slice
const bikesSlice = createSlice({
  name: 'bikes',
  initialState,
  reducers: {
    clearBikes: (state, action) => {
      return initialState
    },
    setBikes: (state, action) => {
      const { payload } = action
      state.models = {
        byId: payload.bikesById,
        allIds: payload.bikesAllIds,
      }
    },
    setBikeInfo: (state, action) => {
      const { payload } = action
      state.models.byId[payload.id] = {
        ...state.models.byId[payload.id],
        ...payload.info,
      }
    },
  },
})

// Reducers
export default bikesSlice.reducer

// Selectors
export const bikesSelector = state => state.bikes

export const selectBikeById = (state, bikeId) => {
  return state.bikes.models.byId ? state.bikes.models.byId[bikeId] : null
}

// Actions
export const { setBikes, setBikeInfo, clearBikes } = bikesSlice.actions

const normalizeAllBikes = original => {
  return original.reduce((acc, curr) => ((acc[curr['id']] = curr), acc), {})
}

const normalizeAllBikeIds = original => {
  return original.map(bike => bike.id)
}

export const selectBike = option => dispatch => {
  // dispatch(clearBikes());
  dispatch(clearAccessories())
  // navigate(`/bike/${option}`)
}

// Thunks
export const fetchBikes = (recycle = false) => dispatch => {
  dispatch(setBikes({ bikesById: [], bikesAllIds: [] }))
  const { name, path } = recycle ? recycleAPI : bikeAPI
  console.log('fetchBikes', name, path)
  API.get(name, path)
    .then(response => {
      let bikesById = normalizeAllBikes(response.bikes)
      let bikesAllIds = normalizeAllBikeIds(response.bikes)
      dispatch(setBikes({ bikesById, bikesAllIds }))
    })
    .catch(error => {
      console.log('error', error)
    })
}

export const fetchBikeInfo = (id, recycle = false) => dispatch => {
  const { name, path } = bikeAPI
  API.get(name, `${path}/${id}/${recycle ? 'rc' : 'nr'}`)
    .then(response => {
      dispatch(setBikeInfo({ id, info: response.bike }))
    })
    .catch(error => {
      console.log(error)
    })
}
