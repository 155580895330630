import { createSlice, PayloadAction,  current} from '@reduxjs/toolkit'
import store from '../store'
import { RootState, Thunk, Dispatch } from '../store'
import API from '@aws-amplify/api'
import { bikeAPI } from '../../config'
import { clearAccessories } from '../accessories'
import { navigate } from 'gatsby'

export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark',
}

const initialState = {
  error: null,
  purchaseType: 'subscription',
}

// Slice
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showError: (state, action) => {
      state.error = action.payload
    },
    clearError: (state, action) => {
      state.error = null
    },
    switchPurchase: (state, action) => {
      if(action.payload.purchaseType === "subscription"){
        state.purchaseType = "subscription"
      }else{
        state.purchaseType = "cycletowork"
      }
    },
  },
})

// Reducers
export default uiSlice.reducer

// Selectors
// Actions
export const { showError, clearError, switchPurchase } = uiSlice.actions
