import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import store from '../store'
import { RootState, Thunk, Dispatch } from '../store'
import API from '@aws-amplify/api'
import { orderAPI, subscriptionAPI } from '../../config'

const initialState = {
  id: null,
  email: null,
}

// Slice
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrder: (state, action) => {
      const { payload } = action
      return {
        ...state,
        ...payload,
      }
    },
    setEmail: (state, action) => {
      const { payload } = action
      return {
        ...state,
        email: payload,
      }
    },
    checkPass: (state, action) => {
      const { payload } = action
      return {
        ...state,
        check: payload,
      }
    },
    clearOrder: (state, action) => {
      return initialState
    },
  },
})

// Reducers
export default orderSlice.reducer

// Selectors
export const orderSelector = state => state.order

// Actions
const { setOrder, setEmail, checkPass, clearOrder } = orderSlice.actions

// Thunks
export const placeOrder = data => (dispatch, getState) => {
  let { name, path } = orderAPI
  let { selection } = getState()


  API.post(name, path, { body: { ...data, ...selection } })
    .then(response => {
      dispatch(setOrder({ id: response.result }))
    })
    .catch(error => {
      console.log('error', error)
    })
}

export const placeSubscriptionOrder = data => (dispatch, getState) => {
  let { name, path } = subscriptionAPI
  let { selection, discount, ...rest } = getState()

  let body = { ...data, ...selection, ...discount }

  let bikeSubPrice = (
    body.selectedBike.subPrice + parseFloat(body.accessoriesMonthlyTotal)
  ).toFixed(2)

  API.post(name, path, { body: { ...body, subPrice: bikeSubPrice } })
    .then(response => {
      dispatch(setOrder({ ...response, id: response.orderID }))
      dispatch(setEmail(data.value.emailPersonal))
    })
    .catch(error => {
      console.log('error', error)
    })
}

export const matchAccountToOrder = data => (dispatch, getState) => {
  let { name, path } = subscriptionAPI

  console.log('data', data)
  API.post(name, `${path}/account`, { body: data })
    .then(response => {
      console.log('response mathcing usersub', response)
      // dispatch(setOrder({ ...response, id: response.orderID }))
      // dispatch(setEmail(data.value.emailPersonal))
    })
    .catch(error => {
      console.log('error', error)
    })
}

export const setCreditCheck = data => (dispatch, getState) => {
  dispatch(checkPass(data))
}

export const clearOrders = data => (dispatch, getState) => {
  dispatch(clearOrder())
}
