import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import store from '../store'
import { RootState, Thunk, Dispatch } from '../store'
import API from '@aws-amplify/api'
import { bikeAPI } from '../../config'
import { navigate } from 'gatsby'
import { clearAccessories } from '../accessories'

import { fetchQuote, resetQuote } from '../quote'

import { fbEvent, gaEvent } from '../../helpers'

export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark',
}

const initialState = {
  salary: null,
  selectedBike: {
    color: null,
    model: null,
    size: null,
    image: null,
    includedAccessories: [],
  },
  defaultRemoved: false,
  selectedAccessories: [],
  accessoriesTotal: '0.00',
  accessoriesMonthlyTotal: '0.00',
  accessoriesFP: '0.00',
  accessoriesInsured: '0.00',
  selectedPlan: null,
}

// Slice
const selectionSlice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    selectModel: (state, action) => {
      const { payload } = action

      return {
        ...state,
        ...initialState,
        selectedBike: {
          ...state.selectedBike,
          recycle: payload.recycle,
          model: payload.model,
          price: payload.price,
          subPrice: payload.subPrice,
          includedAccessories: payload.includedAccessories,
        },
        // selectedAccessories: [],
        // selectedPlan: null,
        // accessoriesTotal: '0.00',
        // accessoriesFP: '0.00',
        // accessoriesInsured: '0.00',
      }
    },
    selectColor: (state, action) => {
      const { payload } = action
      return {
        ...state,
        selectedBike: {
          ...state.selectedBike,
          color: payload.color,
          hex: payload.hex,
          image: payload.image,
        },
      }
    },
    selectImage: (state, action) => {
      const { payload } = action
      state.selectedBike.image = payload
    },
    selectSize: (state, action) => {
      const { payload } = action
      state.selectedBike.size = payload
    },
    selectPlan: (state, action) => {
      const { payload } = action
      state.selectedPlan = {
        plan: payload.plan,
        length: payload['length'],
        taxRate: payload.taxRate,
      }
    },
    addAccessory: (state, action) => {
      const { payload } = action

      return {
        ...state,
        selectedAccessories: [...state.selectedAccessories, payload.accessory],
        accessoriesTotal: (
          Number(state.accessoriesTotal) + payload.cost
        ).toFixed(2),
        accessoriesMonthlyTotal: (
          Math.abs(Number(state.accessoriesMonthlyTotal) + payload.subCost
        ).toFixed(2)),
        accessoriesInsured: payload.insured
          ? (Number(state.accessoriesInsured) + payload.cost).toFixed(2)
          : state.accessoriesInsured,
        accessoriesFP: payload.fp
          ? (Number(state.accessoriesFP) + payload.cost).toFixed(2)
          : state.accessoriesFP,
      }
    },
    removeAccessory: (state, action) => {
      const { payload } = action
      return {
        ...state,
        ...payload,
        selectedAccessories: [
          ...state.selectedAccessories.filter(
            item => item !== payload.accessory
          ),
        ],
        accessoriesTotal: (
          Number(state.accessoriesTotal) - payload.cost
        ).toFixed(2),
        accessoriesMonthlyTotal: (
          Number(state.accessoriesMonthlyTotal) - payload.subCost
        ).toFixed(2),
        accessoriesInsured: payload.insured
          ? (Number(state.accessoriesInsured) - payload.cost).toFixed(2)
          : state.accessoriesInsured,
        accessoriesFP: payload.fp
          ? (Number(state.accessoriesFP) - payload.cost).toFixed(2)
          : state.accessoriesFP,
      }
    },
    enterSalary: (state, action) => {
      const { payload } = action
      state.salary = payload
    },
  },
  // extraReducers: {
  //   'accessories/clearAccessories': (state, action) => {
  //     return initialState
  //   },
  // },
})

// Reducers
export default selectionSlice.reducer

// Selectors
export const selectionSelector = state => state.selection

// Actions
export const {
  selectModel,
  selectSize,
  selectColor,
  addAccessory,
  removeAccessory,
  enterSalary,
  selectPlan,
} = selectionSlice.actions

// Thunks
export const selectedModel = ({
  model,
  price,
  includedAccessories,
  subPrice,
  recycle
}) => dispatch => {
  dispatch(clearAccessories());
  dispatch(selectModel({ model, price, includedAccessories, subPrice, recycle }))
}

export const selectedSize = size => dispatch => {
  dispatch(selectSize(size))
}

export const selectedColor = ({ color, image, hex }) => dispatch => {
  dispatch(selectColor({ color, hex, image }))
}

export const selectedSalary = salary => dispatch => {
  dispatch(enterSalary(salary))
}

export const selectedPlan = ({ plan, length }) => (dispatch, getState) => {
  const {
    quote: { taxRate },
  } = getState()

  dispatch(selectPlan({ plan, length, taxRate }))
}

export const addDefaultAccessories = accessory => (dispatch, getState) => {
  let {
    quote,
    selection: { selectedAccessories, selectedPlan, defaultRemoved },
  } = getState()

  if (!selectedAccessories.includes(accessory.ID) && !defaultRemoved) {
    dispatch(
      addAccessory({
        accessory: accessory.ID,
        cost: accessory.RRP,
        subCost: accessory['Sub Monthly'],
        insured: accessory['Insured'],
        fp: accessory['Final Payment'],
      })
    )
  }
}

export const selectedAccessories = (accessory, defaultLock) => (dispatch, getState) => {
  let {
    quote,
    selection: { selectedAccessories, selectedPlan },
  } = getState()

  if (selectedAccessories.includes(accessory.ID)) {
    dispatch(
      removeAccessory({
        defaultRemoved: defaultLock,
        accessory: accessory.ID,
        cost: accessory.RRP,
        subCost: accessory['Sub Monthly'],
        insured: accessory['Insured'],
        fp: accessory['Final Payment'],
      })
    )
  } else {
    dispatch(
      addAccessory({
        defaultRemoved: defaultLock,
        accessory: accessory.ID,
        cost: accessory.RRP,
        subCost: accessory['Sub Monthly'],
        insured: accessory['Insured'],
        fp: accessory['Final Payment'],
      })
    )
    // gaEvent('add-accessory', accessory)
    // fbEvent('AddToCart', accessory)
  }
  if (quote?.['12']) {
    dispatch(fetchQuote())
  }
}
