export const bikeAPI = {
    name: 'bikes',
    path: '/bikes'
}

export const recycleAPI = {
    name: 'bikes',
    path: '/bikes/recycle'
}


export const orderAPI = {
    name: 'orders',
    path: '/orders'
}

export const subscriptionAPI = {
    name: 'subscriptions',
    path: '/subscriptions'
}

export const quoteAPI = {
    name: 'blikeapi',
    path: '/quote'
}

export const employersAPI = {
    name: 'employers',
    path: '/employers'
}

export const employeesAPI = {
    name: 'employees',
    path: '/employees'
}


export const usersAPI = {
    name: 'users',
    path: '/users'
}

export const userAPI = {
    name: 'user',
    path: '/user'
}

export const contactsAPI = {
    name: 'contacts',
    path: '/contacts'
}

export const emailCheckAPI = {
    name: 'emailcheck',
    path: '/emailcheck'
}

export const creditcheckAPI = {
    name: 'creditCheck',
    path: '/creditcheck'
}

export const postcodeAPI = {
    name: 'postcodeLookup',
    path: '/postcode'
}

export const goCardlessAPI = {
    name: 'gocardless',
    path: '/gocardless'
}

export const discountAPI = {
    name: 'discounts',
    path: '/discounts'
}