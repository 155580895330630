/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "api289849dd",
            "endpoint": "https://5wjscixd40.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "bikes",
            "endpoint": "https://y09pb4z98e.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "blikeapi",
            "endpoint": "https://8u1nbwilsd.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "contacts",
            "endpoint": "https://0a3erdu04i.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "creditCheck",
            "endpoint": "https://potks6xks9.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "discounts",
            "endpoint": "https://gu66558pdj.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "emailcheck",
            "endpoint": "https://i1ai35nrn8.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "employees",
            "endpoint": "https://1fdo5le6hl.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "employers",
            "endpoint": "https://xrhnh27lzc.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "gocardless",
            "endpoint": "https://aydeby6mhh.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "orders",
            "endpoint": "https://u6pwtayfg7.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "postcodeLookup",
            "endpoint": "https://3bnym6lcgd.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "subscriptionCosting",
            "endpoint": "https://991uhogdol.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "subscriptions",
            "endpoint": "https://68x9j4o90m.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "users",
            "endpoint": "https://dk8cmopkt0.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "xero",
            "endpoint": "https://gedd9y7nzk.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "xeroWebhook",
            "endpoint": "https://f5acvjty96.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:762cf646-1c52-4f5d-a02b-b78aaacb0c2f",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_jUvQzslqH",
    "aws_user_pools_web_client_id": "45f8qdueoshasn1odj032lq4dh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
