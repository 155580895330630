import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
  Action,
} from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
import logger from 'redux-logger'
import LogRocket from 'logrocket'

import storage from 'redux-persist/lib/storage'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import bikesReducer from '../state/bikes'
import selectionReducer from '../state/selection'
import quoteReducer from '../state/quote'
import accessoryReducer from '../state/accessories'
import orderReducer from '../state/order'
import uiReducer from '../state/ui'
import employeeReducer from '../state/employee'
import discountReducer from '../state/discount'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  bikes: bikesReducer,
  selection: selectionReducer,
  quote: quoteReducer,
  accessories: accessoryReducer,
  order: orderReducer,
  ui: uiReducer,
  employee: employeeReducer,
  discount: discountReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(process.env.NODE_ENV !== 'production' ? logger : [])
      .concat(
        process.env.NODE_ENV === 'production' ? LogRocket.reduxMiddleware() : []
      ),
  devTools: process.env.NODE_ENV !== 'production',
})

// export RootState = ReturnType<typeof rootReducer>;
// export Dispatch = typeof store.dispatch;
// export type Thunk = ThunkAction<void, RootState, null, Action<string>>;

export default store
