import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import store from '../store'
import { RootState, Thunk, Dispatch } from '../store'
import API from '@aws-amplify/api'
import { bikeAPI } from '../../config'

export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark',
}

const initialState = {
  byId: {},
  allIds: [],
}

// Slice
const accessoriesSlice = createSlice({
  name: 'accessories',
  initialState,
  reducers: {
    setAccessories: (state, action) => {
      const { payload } = action
      return { ...state, ...payload }
    },
    clearAccessories: (state, action) => {
      return initialState
    },
  },
})

// Reducers
export default accessoriesSlice.reducer

// Selectors
export const accessoriesSelector = state => state.accessories

// Actions
export const { setAccessories, clearAccessories } = accessoriesSlice.actions

const normalizeAllAccessories = original => {
  return original.reduce((acc, curr) => ((acc[curr['ID']] = curr), acc), {})
}

const normalizeAllSourceIds = original => {
  return original.reduce((acc, curr) => ((acc[curr['Source ID']] = curr), acc), {})
}

const normalizeAllBikeIds = original => {
  return original.map(bike => bike['ID'])
}

// Thunks


var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};


export const fetchAccessories = (recycle) => (dispatch, getState) => {
  const { name, path } = bikeAPI
  const { selection } = getState()

  selection.selectedBike &&
    API.get(name, `${path}/${selection.selectedBike.model}/${recycle ? 'rc' : 'nr'}/accessories`)
      .then(response => {
        let byId = normalizeAllAccessories(response.accessories)
        let allIds = normalizeAllBikeIds(response.accessories)
        let sourceIds = normalizeAllSourceIds(response.accessories)
        let { categories } = response
        dispatch(setAccessories({ byId, allIds, sourceIds, categories }))
      })
      .catch(error => {
        console.log('error', error)
      })
}

// export const fetchBikeInfo = id => dispatch => {
//   const { name, path } = bikeAPI
//   API.get(name, `${path}/${id}`)
//     .then(response => {
//       dispatch(setBikeInfo({ id, info: response.bike }))
//     })
//     .catch(error => {
//       console.log(error)
//     })
// }
