export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const toTitleCase = str => {
  return str
    ? str.toString().replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    : ''
}

export const gaEvent = (event = '', variables = null) => {
  const isBrowser = typeof window !== 'undefined'
  isBrowser && window?.dataLayer?.push({ event: event, variables })
}

export const fbEvent = (event = '', variables = null) => {
  if (typeof window !== 'undefined') {
    if (window.fbq != null) {
      window.fbq('track', event, { ...variables })
    }
  }
}
