import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import store from '../store'
import { RootState, Thunk, Dispatch } from '../store'
import API from '@aws-amplify/api'
import { quoteAPI } from '../../config'
import { selectPlan } from '../selection'

export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark',
}

const initialState = {
  12: null,
  24: null,
  36: null,
}

// Slice
const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setLoading: (state, action) => ({
      ...initialState,
      loading: true,
    }),
    clearQuote: (state, action) => ({
      ...initialState,
    }),
    setQuote: (state, action) => {
      const { payload } = action
      return {
        taxRate: payload.taxRate,
        12: {
          net: payload['12'].net,
          gross: payload['12'].gross,
          finalOwnership: 25,
          finalPayment: payload['12'].final,
          packageValue: payload['12'].packageValue,
          saving: payload['12'].saving,
          savingPercent: payload['12'].savingPercent,
        },
        24: {
          net: payload['24'].net,
          gross: payload['24'].gross,
          finalOwnership: 17,
          finalPayment: payload['24'].final,
          packageValue: payload['24'].packageValue,
          saving: payload['24'].saving,
          savingPercent: payload['24'].savingPercent,
        },
        36: {
          net: payload['36'].net,
          gross: payload['36'].gross,
          finalOwnership: 12,
          finalPayment: payload['36'].final,
          packageValue: payload['36'].packageValue,
          saving: payload['36'].saving,
          savingPercent: payload['36'].savingPercent,
        },
        loading: false,
      }
    },
  },
  extraReducers: {
    'accessories/clearAccessories': (state, action) => {
      return initialState
    },
  },
})

// Reducers
export default quoteSlice.reducer

// Selectors
export const quoteSelector = state => state.quote

// Actions
const { setQuote, setLoading, clearQuote } = quoteSlice.actions

// Thunks

export const resetQuote = () => (dispatch, getState) => {
  dispatch(clearQuote())
}

export const fetchQuote = () => (dispatch, getState) => {
  dispatch(setLoading())

  const {
    selection: {
      selectedPlan,
      salary,
      accessoriesTotal,
      accessoriesInsured,
      accessoriesFP,
      selectedBike: { model, price },
    },
  } = getState()

  const { name, path } = quoteAPI

  const data = {
    salary: salary,
    bike: price,
    accessoriesAll: Number(accessoriesTotal),
    accessoriesInsured: Number(accessoriesInsured),
    accessoriesFP: Number(accessoriesFP),
  }

  API.post(name, path, { body: data })
    .then(response => {
      dispatch(setQuote(response.result))
      if (selectedPlan) {
        let { plan, length, taxRate } = selectedPlan
        dispatch(selectPlan({ plan: response.result[length], length, taxRate }))
      }
    })
    .catch(error => {
      console.log('error', error)
    })
}
