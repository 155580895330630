import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  employer: null,
  email: null,
  employerName: null
}

// Slice
const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    addCode: (state, action) => {
      console.log("THIS FIRES");
      state.email = action.payload.email
      state.employer = action.payload.employer
      state.employerName = action.payload.employerName
    },
    clearCode: (state, action) => {
      return initialState
    },
  },
})

// Reducers
export default employeeSlice.reducer

// Selectors
// Actions
export const { addCode, clearCode } = employeeSlice.actions
